$(document).ready(function () {
    $('.c-carousel > .c-carousel__list').slick({
        infinite: true,
        autoplay: true,
        arrows: false,
        dots: false,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    $('.c-magnific-popup').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('table').each(function (i, el) {
        $(el).wrap("<div class='c-table__wrapper'></div>");
    });

    $('.c-menu__summary').on('click', function() {
        $('body').toggleClass("menu-is-open");
    })
});